import { StateCreator } from 'zustand';
import { Slices } from '../types';
import { GptAdsSlice, GptAdsConfig, AdsTargeting } from './types';
import { SanityAdsTargeting } from '../sanity/types';
import {
  AD_UNITS_MAPPING,
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from './constants';
import { generateAdUnitPath, mapSearchParamsToTargeting } from './utils';

type AdsObjectItem = {
  key: string;
  value: string;
};

export const createGptAdsSlice =
  (config: GptAdsConfig): StateCreator<Slices, [], [], GptAdsSlice> =>
  (set, get) => ({
    config,
    adUnitsMapping: AD_UNITS_MAPPING,
    adUnitsRequestedToDisplay: [],
    isInitialized: false,
    init: (staticAdUnitCodes, targeting, anchorAdUnitCode) => {
      const config = get().gptAds.config;
      get().gptAds._pushCmd(() => {
        // Set page-level targeting
        Object.entries(targeting).forEach(([key, value]) => {
          googletag.pubads().setTargeting(key, value);
        });

        // Define all ad slots that are displayed on the page
        staticAdUnitCodes.forEach((adUnitCode) => {
          const adUnitSizes = get().gptAds.adUnitsMapping[adUnitCode];
          const sizeMapping = googletag
            .sizeMapping()
            .addSize(MOBILE_BREAKPOINT, adUnitSizes.mobileSize)
            .addSize(DESKTOP_BREAKPOINT, adUnitSizes.desktopSize)
            .build();
          const slot = googletag.defineSlot(
            generateAdUnitPath(
              adUnitCode,
              config.networkCode,
              config.parentAdUnitCode
            ),
            adUnitSizes.mobileSize,
            adUnitCode
          );
          if (slot) {
            if (sizeMapping) slot.defineSizeMapping(sizeMapping);
            slot.addService(googletag.pubads());
          }
        });

        let anchorAdSlot;
        if (anchorAdUnitCode) {
          anchorAdSlot = googletag.defineOutOfPageSlot(
            generateAdUnitPath(
              anchorAdUnitCode,
              config.networkCode,
              config.parentAdUnitCode
            ),
            googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR
          );
          if (anchorAdSlot) {
            anchorAdSlot.addService(googletag.pubads());
          }
        }

        googletag.pubads().enableSingleRequest();
        googletag.enableServices();

        set((state) => ({
          ...state,
          gptAds: { ...state.gptAds, isInitialized: true },
        }));

        // Run `googletag.display` command for slots that were rendered before GPT is initialized
        get().gptAds.adUnitsRequestedToDisplay.forEach((adUnitCode) => {
          googletag.display(adUnitCode);
        });

        // Run `googletag.display` command for anchor ad exclusively if static ad units are not present on the page
        // Otherwise, there is no need to run `googletag.display` for anchor ad as it will be displayed with
        // the first static ad unit as we are using Single Request Architecture
        if (anchorAdSlot && !staticAdUnitCodes.length) {
          googletag.display(anchorAdSlot);
        }
      });
    },
    displayUnit: (adUnitCode) => {
      set((state) => ({
        ...state,
        gptAds: {
          ...state.gptAds,
          adUnitsRequestedToDisplay:
            state.gptAds.adUnitsRequestedToDisplay.concat(adUnitCode),
        },
      }));
      if (get().gptAds.isInitialized) {
        get().gptAds._pushCmd(() => {
          googletag.display(adUnitCode);
        });
      }
    },
    _pushCmd: (cmd) => {
      // @ts-ignore As per official guides, it's fine to assign { cmd: [] } before googletag is loaded
      window.googletag = window.googletag || { cmd: [] };
      return window.googletag.cmd.push(cmd);
    },
    mapSearchResultsSearchParamsToTargeting: (
      searchParams,
      currentDestination
    ) => {
      // TODO Add board basis once it's available in the URL
      return {
        ...mapSearchParamsToTargeting(searchParams),
        destination: currentDestination
          ? currentDestination.names.join(' ').replaceAll("'", '')
          : 'Any Destination',
        pageType: 'search-results',
        pageUrl: window.location.pathname,
        searchType:
          currentDestination && currentDestination.product === 'package_search'
            ? 'sun'
            : 'city',
        tsm_destination: currentDestination
          ? currentDestination.names[0].replaceAll("'", '')
          : 'Any Destination',
        tsm_env: get().gptAds.config.env,
      };
    },
    mapCarHireResultsToTargeting: (aggregate, searchParams) => {
      const [pickUpYear, pickUpMonth, pickUpDay] = aggregate.pickUpDateTime
        .split('T')[0]
        .split('-');
      const pickUpYearShort = pickUpYear.slice(2);
      const [dropOffYear, dropOffMonth, dropOffDay] = aggregate.dropOffDateTime
        .split('T')[0]
        .split('-');
      const dropOffYearShort = dropOffYear.slice(2);
      const prepareText = (text: string) =>
        text.replace(/ /g, '').toLowerCase();
      return {
        tsm_pageType: 'results',
        tsm_env: get().gptAds.config.env,
        tsm_pickup_date: `${pickUpDay}_${pickUpMonth}_${pickUpYearShort}`,
        tsm_pickup_month: `${pickUpDay}_${pickUpMonth}`,
        tsm_dropoff_date: `${dropOffDay}_${dropOffMonth}_${dropOffYearShort}`,
        tsm_drivers_age: searchParams.age,
        tsm_iata: aggregate.pickUpIataCode || '',
        tsm_pickup_location: prepareText(aggregate.pickUpLocation),
        tsm_pickup_admin1: aggregate.pickUpArea1
          ? prepareText(aggregate.pickUpArea1)
          : '',
        tsm_pickup_admin2: aggregate.pickUpArea2
          ? prepareText(aggregate.pickUpArea2)
          : '',
        tsm_pickup_country: prepareText(aggregate.pickUpCountry),
      };
    },
    mapDestinationFinderSearchParamsToTargeting: (searchParams) => {
      return {
        ...mapSearchParamsToTargeting(searchParams),
        pageType: 'trip-finder',
        pageUrl: window.location.pathname,
        searchType: 'sun',
        tsm_destination: 'Any Destination',
        tsm_env: get().gptAds.config.env,
      };
    },

    mapSanityAdsToLandingPageTargeting: (sanityAds?: SanityAdsTargeting) => {
      const obj: AdsTargeting = {
        pageType: sanityAds?.pageType || '',
        tsm_env: get().gptAds.config.env,
        pageUrl: window.location.pathname,
        site: get().gptAds.config.site,
      };

      if (sanityAds?.additional) {
        sanityAds?.additional.forEach((item: AdsObjectItem) => {
          obj[item.key] = item.value;
        });
      }
      return obj;
    },
    mapSanityAdsToTargeting: (sanityAds?: SanityAdsTargeting) => {
      const obj: AdsTargeting = {};
      if (sanityAds?.additional) {
        sanityAds?.additional.forEach((item: AdsObjectItem) => {
          obj[item.key] = item.value;
        });
      }
      return obj;
    },
    mapExposedResultsToTargeting: (definition) => {
      const destination = definition.destinationCodes
        .replaceAll('-', ' ')
        .replaceAll('|', ',');
      return {
        pageType: 'exposed-results',
        tsm_env: get().gptAds.config.env,
        pageUrl: window.location.pathname,
        adults: definition.adults.toString(),
        children: definition.children.toString(),
        departureAirports: definition.departureCode,
        duration: definition.duration.toString(),
        destination,
        tsm_destination: destination,
      };
    },
  });
