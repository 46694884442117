import { AdUnitsMapping } from './types';

export const MOBILE_BREAKPOINT: googletag.SingleSizeArray = [320, 0];
export const DESKTOP_BREAKPOINT: googletag.SingleSizeArray = [1024, 0];

const repeatedUnits = (name: string) => {
  return {
    [`${name}/1`]: {
      mobileSize: [
        [300, 50],
        [300, 75],
        [320, 50],
        [320, 75],
      ],
      desktopSize: [[728, 90]],
    },
    [`${name}/2`]: {
      mobileSize: [
        [300, 250],
        [300, 600],
      ],
      desktopSize: [
        [160, 600],
        [300, 600],
        [300, 250],
      ],
    },
    [`${name}/3`]: {
      mobileSize: [
        [300, 50],
        [300, 75],
        [320, 50],
        [320, 75],
      ],
      desktopSize: [
        [728, 90],
        [970, 250],
      ],
    },
    [`${name}/4`]: {
      mobileSize: [
        [300, 50],
        [300, 75],
        [320, 50],
        [320, 75],
      ],
      desktopSize: [[728, 90]],
    },
    [`${name}/5`]: {
      mobileSize: [[1, 5]],
      desktopSize: [[1, 5]],
    },
  };
};

const resultsUnits = (name: string) => {
  return {
    [`${name}/1`]: {
      mobileSize: [
        [300, 250],
        [300, 600],
      ],
      desktopSize: [
        [300, 250],
        [300, 600],
      ],
    },
    [`${name}/2`]: {
      mobileSize: [
        [300, 250],
        [300, 600],
      ],
      desktopSize: [[300, 250]],
    },
    [`${name}/3`]: {
      mobileSize: [
        [300, 250],
        [300, 600],
      ],
      desktopSize: [[300, 250]],
    },
    [`${name}/4`]: {
      mobileSize: [
        [300, 250],
        [300, 600],
      ],
      desktopSize: [[300, 250]],
    },
    [`${name}/5`]: {
      mobileSize: [
        [300, 50],
        [320, 50],
        [300, 75],
        [320, 75],
      ],
      desktopSize: [[728, 90]],
    },
  };
};

export const AD_UNITS_MAPPING: AdUnitsMapping = {
  'blog/1': {
    mobileSize: [[300, 250]],
    desktopSize: [
      [300, 250],
      [300, 600],
    ],
  },
  'blog/2': {
    mobileSize: [[300, 250]],
    desktopSize: [
      [300, 250],
      [300, 600],
    ],
  },
  'blog/3': {
    mobileSize: [[300, 250]],
    desktopSize: [
      [300, 250],
      [720, 90],
    ],
  },
  'blog/4': {
    mobileSize: [
      [300, 50],
      [320, 50],
      [300, 75],
      [320, 75],
    ],
    desktopSize: [[728, 90]],
  },
  'trip-finder/1': {
    mobileSize: [],
    desktopSize: [
      [970, 90],
      [728, 90],
    ],
  },
  'trip-finder/2': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [970, 90],
      [728, 90],
    ],
  },
  'deals/top-deals/1': {
    mobileSize: [
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [970, 90],
      [728, 90],
    ],
  },
  'deals/top-deals/2': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [970, 90],
      [728, 90],
    ],
  },
  'deals/top-deals/3': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [970, 250],
      [970, 90],
      [728, 90],
    ],
  },
  'deals/top-deals/4': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [300, 600],
      [300, 250],
      [160, 600],
    ],
  },
  'deals/top-deals/5': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [300, 600],
      [300, 250],
      [160, 600],
    ],
  },
  'exposed-results/1': {
    mobileSize: [
      [300, 50],
      [320, 50],
      [300, 75],
      [320, 75],
    ],
    desktopSize: [[728, 90]],
  },
  'destinations-homepage/1': {
    mobileSize: [
      [300, 50],
      [320, 50],
      [300, 75],
      [320, 75],
    ],
    desktopSize: [[728, 90]],
  },
  'destinations-homepage/2': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [970, 90],
      [728, 90],
    ],
  },
  'destinations-homepage/3': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [970, 250],
      [970, 90],
      [728, 90],
    ],
  },
  ...resultsUnits('holiday-search'),
  ...resultsUnits('carhire-search'),
  ...resultsUnits('flights-search'),
  ...resultsUnits('hotels-search'),
  ...repeatedUnits('carhire-homepage'),
  ...repeatedUnits('carhire-destination'),
  ...repeatedUnits('flights-homepage'),
  ...repeatedUnits('flights-destination'),
  ...repeatedUnits('holidays-homepage'),
  ...repeatedUnits('holidays-destination'),
  ...repeatedUnits('hotels-homepage'),
  ...repeatedUnits('hotels-destination'),
  ...repeatedUnits('travel-insurance-homepage'),
  ...repeatedUnits('travel-insurance-destination'),
};
