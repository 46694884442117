let anchorAds: NodeListOf<HTMLElement> | null = null;

export const findAnchorAds = () => {
  anchorAds = document.querySelectorAll<HTMLElement>("[id*='-anchor-bottom']");
};

export const showAnchorAds = () => {
  findAnchorAds();
  if (anchorAds && anchorAds.length) {
    anchorAds[0].style.display = 'block';
  }
};

export const hideAnchorAds = () => {
  findAnchorAds();
  if (anchorAds && anchorAds.length) {
    anchorAds[0].style.display = 'none';
  }
};
